// Sidebar
$sidebar-link-padding: 8px 35px 8px 8px;
$sidebar-icon-size: 21px;
$sidebar-first-level-padding: 0 0 10px 0;
$sidebar-first-level-link-padding: 10px 8px;
$sidebar-first-level-icon-size: 14px;
$sidebar-second-level-padding: 0 0 0px 20px;
/*******************
Main sidebar
******************/

.left-sidebar {
  position: absolute;
  width: $sidebar-width-full;
  height: 100%;
  top: 0px;

  z-index: 10;
  padding-top: 70px;
  background: #ffffff !important;
  box-shadow: $shadow;
  transition: 0.2s ease-in;
}

/*******************
sidebar navigation
******************/
.scroll-sidebar {
  height: calc(100% - 20px);
  overflow: auto;
}

.sidebar-nav {
  ul {
    .sidebar-item {
      width: 100%;
      margin-bottom: 10px;
      .sidebar-link {
        color: $sidebar-text;
        padding: $sidebar-link-padding;
        display: flex;
        white-space: nowrap;
        align-items: center;
        line-height: 25px;
        cursor: pointer;
        i {
          font-style: normal;
          width: 30px;
          line-height: 25px;
          font-size: $sidebar-icon-size;
          color: $sidebar-icons;
          display: inline-block;
          text-align: center;
        }
      }
      &.selected > .sidebar-link {
        background: transparent !important;
        border-radius: 4px;
        color: $white;
        opacity: 1;
        font-weight: 400;
        i {
          color: $white;
        }
        &:after {
          border-color: $white;
        }
      }

      /*******************
      First level
      ******************/
      .first-level {
        padding: $sidebar-first-level-padding;
        background: transparent !important;

        .sidebar-item {
          margin-bottom: 0px;
          padding-left: 5%;
        }
        .sidebar-item.selected .sidebar-link {
          opacity: 1;
          font-weight: 800;
          background: transparent;
          box-shadow: none;
          &:hover {
            color: $success;
          }
        }
        .sidebar-item .sidebar-link {
          padding: $sidebar-first-level-link-padding;
          i {
            font-size: $sidebar-first-level-icon-size;
            visibility: hidden;
          }
        }
      }
      .second-level .sidebar-item {
        padding: $sidebar-second-level-padding;
      }
    }
    /*******************
         Small cap
         ******************/
    .nav-small-cap {
      font-size: 12px;
      padding: $sidebar-link-padding;
      white-space: nowrap;
      display: flex;
      align-items: center;
      line-height: 30px;
      margin-top: 10px;
      color: $sidebar-icons;
      font-weight: 500;
      text-transform: uppercase;
      i {
        line-height: 30px;
        margin: 0 5px;
      }
    }
  }
}
/*******************
 common m-p removed
******************/
.sidebar-nav {
  ul {
    margin: 0px;
    padding: 15px;
    li {
      list-style: none;
    }
  }
}
.collapse.in {
  display: block;
}
/*******************
Down arrow
******************/
.sidebar-nav .has-arrow {
  position: relative;
  &::after {
    position: absolute;
    content: '';
    width: 8px;
    height: 8px;
    border-width: 1px 0 0 1px;
    border-style: solid;
    border-color: $sidebar-text;
    margin-left: 10px;
    -webkit-transform: rotate(135deg) translate(0, -50%);
    -ms-transform: rotate(135deg) translate(0, -50%);
    -o-transform: rotate(135deg) translate(0, -50%);
    transform: rotate(135deg) translate(0, -50%);
    -webkit-transform-origin: top;
    -ms-transform-origin: top;
    -o-transform-origin: top;
    transform-origin: top;
    top: 23px;
    right: 15px;
    -webkit-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
  }
}

/*.sidebar-nav .active > .has-arrow::after,*/

/*.sidebar-nav li.selected > .has-arrow::after,*/
.sidebar-nav li.active > .has-arrow::after,
.sidebar-nav li > .has-arrow.active::after,
.sidebar-nav .has-arrow[aria-expanded='true']::after {
  -webkit-transform: rotate(-135deg) translate(0, -50%);
  -ms-transform: rotate(-135deg) translate(0, -50%);
  -o-transform: rotate(-135deg) translate(0, -50%);
  transform: rotate(-135deg) translate(0, -50%);
}

/*******************
use profile section
******************/

.user-profile {
  position: relative;
  background-size: cover;
  padding: 0;
  .profile-img {
    width: 50px;
    margin-left: 30px;
    padding: 35px 0;
    border-radius: 100%;
    &::before {
      -webkit-animation: 2.5s blow 0s linear infinite;
      animation: 2.5s blow 0s linear infinite;
      position: absolute;
      content: '';
      width: 50px;
      height: 50px;
      margin: 0 auto;
      border-radius: 50%;
      z-index: 0;
    }
    @-webkit-keyframes blow {
      0% {
        box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.1);
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 0.5);
        transform: scale3d(1, 1, 0.5);
      }
      50% {
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 0.5);
        transform: scale3d(1, 1, 0.5);
      }
      100% {
        box-shadow: 0 0 0 20px rgba(0, 0, 0, 0.1);
        opacity: 0;
        -webkit-transform: scale3d(1, 1, 0.5);
        transform: scale3d(1, 1, 0.5);
      }
    }
    @keyframes blow {
      0% {
        box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.1);
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 0.5);
        transform: scale3d(1, 1, 0.5);
      }
      50% {
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 0.5);
        transform: scale3d(1, 1, 0.5);
      }
      100% {
        box-shadow: 0 0 0 20px rgba(0, 0, 0, 0.1);
        opacity: 0;
        -webkit-transform: scale3d(1, 1, 0.5);
        transform: scale3d(1, 1, 0.5);
      }
    }
    img {
      width: 100%;
      border-radius: 100%;
    }
  }
  .profile-text {
    position: relative;
    > .dropdown > .dropdown-toggle {
      color: $white !important;
      width: 100%;
      padding: 7px 30px;
      background: rgba(0, 0, 0, 0.5);
      display: block;
      border: none;
      text-align: left;
      &:after {
        position: absolute;
        right: 20px;
        top: 13px;
      }
    }
  }
  .dropdown-menu {
    left: 0px;
    right: 0px;
    width: 180px;
    margin: 0 auto;
  }
}

.profile-dd {
  position: relative;
  .user-dd {
    padding: 6px 17px;
    background: $skin6;
    opacity: 0.7 !important;
    width: 100%;
    &:focus,
    &:active {
      background-color: rgba(0, 0, 0, 0.5) !important;
      box-shadow: none !important;
    }
    &.dropdown-toggle::after {
      position: absolute;
      right: 20px;
      top: 16px;
    }
  }
  .btn-group {
    width: 100%;
  }
  .profile-user-img {
    padding: 0px;
  }
}
